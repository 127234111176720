<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav currentPage="首页" class="head-nav"></headNav>
    </div>

    <div class="body">
      <supportDetail></supportDetail>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import supportDetail from "../components/container/support/supportDetail";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "NewsDetail",
  components: {
    yicangHead,
    headNav,
    supportDetail,
    footerBox
  },
  methods: {
  }
};
</script>
