<template>
  <div class="news-detail-container">
    <div class="head-nav fs-16 fc-3">
      <span class="back" @click="goBack">返回</span>
    </div>
    <div class="detail-content">
      <img :src="logo" alt class="logo" />
      <div class="news-title fs-24 fc-1">{{supportName}}</div>
      <div class="news-content fs-16 fc-3" v-html="content"></div>
    </div>
  </div>
</template>

<script>
import { SUPPORT_LIST } from "@/static/supportList";

export default {
  name: "newsDetail",
  data() {
    return {
      logo: SUPPORT_LIST[0].imgUrl,
      supportName: SUPPORT_LIST[0].name,
      content: SUPPORT_LIST[0].content
    };
  },
  mounted() {
    const {
      supportName = SUPPORT_LIST[0].name,
      preIndex = 0,
      index = 0
    } = this.$route.query;
    this.logo = "";
    this.chooseBrand(supportName, preIndex, index);
  },
  methods: {
    chooseBrand(supportName, preIndex, index) {
      this.supportName = supportName;
      this.content = SUPPORT_LIST[preIndex][index].content;
      this.logo = SUPPORT_LIST[preIndex][index].imgUrl;
    },

    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
.news-detail-container {
  padding: 0.56rem 2.8rem;
  min-height: 4rem;
  .back {
    cursor: pointer;
  }
  .detail-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .news-title {
      text-align: center;
      padding: 0.36rem 0;
      font-weight: 600;
      position: relative;
    }
    .logo {
      width: 1rem;
    }
    .news-content {
      border-top: 0.01rem solid #eee;
      padding-top: 0.32rem;
      text-indent: 0.32rem;
      p {
        text-align: left;
      }
    }
  }
}
</style>